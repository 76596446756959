import { instance } from '@cling/api'
import config from '@cling/config'
import { setDisabledThirdParties } from '@cling/services/disabledServices'
import logger from '@cling/services/logger'
import actions from '@cling/store/actions'
import application from '@cling/store/modules/application/'
import articles from '@cling/store/modules/articles/'
import certificates from '@cling/store/modules/certificates/'
import companies from '@cling/store/modules/companies/'
import companyReferenceItems from '@cling/store/modules/companyReferenceItems/'
import companyReferences from '@cling/store/modules/companyReferences/'
import companyUsers from '@cling/store/modules/companyUsers/'
import documents2 from '@cling/store/modules/documents2/'
import documentTerms from '@cling/store/modules/documentTerms/'
import endCustomers from '@cling/store/modules/endCustomers/'
import files from '@cling/store/modules/files/'
import forms from '@cling/store/modules/forms/'
import notifications from '@cling/store/modules/notifications/'
import projects from '@cling/store/modules/projects/'
import settings from '@cling/store/modules/settings/'
import templateMessages from '@cling/store/modules/templateMessages/'
import templates from '@cling/store/modules/templates/'
import unitTypes from '@cling/store/modules/unitTypes/'
import events from '@cling/store/plugins/events'
import createPersistedState from '@cling/store/plugins/persistedState'
import VuexReset from '@cling/store/utils/reset'

import Rollbar from 'rollbar'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

let plugins = [
  VuexReset(),
  events,
  ...(notifications.plugins ? [notifications.plugins] : [])
]

// Production only plugins
if (config.environment === 'production') {
  plugins = [...plugins]
}

// Register persistedState plugin but silent if errors occur
try {
  const persistPlugin = createPersistedState({ paths: ['forms'] })
  plugins.push(persistPlugin)
} catch (err) {
  logger.debug(err)
}

const store = new Vuex.Store({
  // strict: config.environment !== 'production',
  modules: {
    application,
    articles,
    companies,
    companyUsers,
    documents2,
    endCustomers,
    forms,
    files,
    notifications,
    projects,
    settings,
    templateMessages,
    certificates,
    companyReferences,
    companyReferenceItems,
    templates,
    documentTerms,
    unitTypes
  },
  // Add global getters and actions
  actions,
  mutations: {
    reset: () => {}
  },
  plugins
})

// Watcher to disable any third parties base on companySetting
// If a user is tracked, and loggs in and the companySetting disables tracking we must install any third parties if they are loaded
store.watch(
  (state, getters) =>
    getters['settings/getCompanySetting']('disabledThirdParties'),
  disabledThirdPartiesSettingValue => {
    if (store.getters['application/isSuperUser']()) return

    if (
      disabledThirdPartiesSettingValue &&
      Array.isArray(disabledThirdPartiesSettingValue) &&
      disabledThirdPartiesSettingValue.length
    ) {
      setDisabledThirdParties(disabledThirdPartiesSettingValue) // Update cookie

      if (disabledThirdPartiesSettingValue.includes('facebook')) {
        if (store.$eventTracker._hasInstalledFbUserPixel)
          store.$eventTracker.uninstallFbUserPixel()
      }

      if (disabledThirdPartiesSettingValue.includes('rollbar')) {
        Rollbar.configure({ enabled: false })
      }
    }
  }
)

export default store
